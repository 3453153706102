.header-nav {

  &__list {
    display: flex;
    align-items: center;
    grid-gap: 15px;
  }

  &__item {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $text--black;
  }
}
