@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Source Sans Pro";

  font-display: swap;
  src: url("../fonts/sourcesansprolight.woff2") format("woff2"), url("../fonts/sourcesansprolight.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Source Sans Pro";

  font-display: swap;
  src: url("../fonts/sourcesanspro.woff2") format("woff2"), url("../fonts/sourcesanspro.woff") format("woff");
}


@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Source Sans Pro";

  font-display: swap;
  src: url("../fonts/sourcesansprosemibold.woff2") format("woff2"), url("../fonts/sourcesansprosemibold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Source Sans Pro";

  font-display: swap;
  src: url("../fonts/sourcesansprobold.woff2") format("woff2"), url("../fonts/sourcesansprobold.woff") format("woff");
}



@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Rubik";

  font-display: swap;
  src: url("../fonts/Rubik.woff2") format("woff2"), url("../fonts/Rubik.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Rubik";

  font-display: swap;
  src: url("../fonts/Rubikmedium.woff2") format("woff2"), url("../fonts/Rubikmedium.woff") format("woff");
}




