.main-form {
  display: flex;
  grid-gap: 30px;

  input {
    height: Min(8.125vw,54px);
    border: 1px solid $color--blue;
    border-radius: 10px;
    text-indent: 2vw;
    font-family: $font-family--s;
    font-style: normal;
    font-weight: bold;
    font-size: Min(5vw,20px);
    line-height: calc(100% + 5px);

    color: $color--black;

    &::placeholder {
      font-family: $font-family--s;
      font-style: normal;
      font-weight: bold;
      font-size: Min(5vw,20px);
      line-height: 100%;

      color: $text--placeholder;
    }
  }

  &__search {
    flex-shrink: 0;
  }
}


.true {
  color: $text--true !important;
}

.false {
  color: $text--false !important;
}
