header {
  background: $header--bg;
  box-shadow: $header-shadow;
  padding: 23px 0;
  position: relative;
  z-index: 2;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 30px;
}

.header-logo {
  flex-grow: 1;
}
