.title-1 {
  font-family: $font-family--s;
  font-style: normal;
  font-weight: bold;
  font-size: Min( 10.938vw, 50px);
  line-height: calc(100% + 10px);
  color: $text--light;
}

.title-2 {
  font-family: $font-family--s;
  font-style: normal;
  font-weight: bold;
  font-size: Min(7.813vw, 40px);
  line-height: calc(100% + 10px);
  color: $text--light;
}

.title-3 {
  font-family: $font-family--s;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: $text--light;
}
