.confirm-box {
  min-width: 66%;
  max-width: 958px;
  margin: 0 auto;

  &__title {
    margin-bottom: 15px;
  }

  &__form {
    align-items: flex-end;
    max-width: 507px;

    fieldset {
      padding: 0;
      border: none;
      width: 100%;
    }

    legend {
      font-family: $font-family--s;
      font-style: normal;
      font-weight: normal;
      font-size: Min( 4.375vw, 18px);
      line-height: Min( 5.625vw, 23px);
      color: $text--light;
      margin-bottom: 5px;
    }
  }
}
